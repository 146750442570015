<template>

  <div :class="{ts: true, active: show_popup, focusing: show_focus, searching: show_clear, loading: show_loader}">  
 
    

    <input       
      id="main_search"
      :placeholder="placeholderInput"
      v-model="name"      
      autocomplete="off"
      @keyup="startSearch"  
      @focus="toggleFocus(true)"
      @blur="toggleFocus(false)"
      rounded
      />

    <div class="clear">
      <i class="icl icon-close" @click="clear"></i>
      <i class="isr icon-search"></i>
      <i class="gloading"></i>    
    </div>
    

    

    <div class="popup" ref="elPopup">      
      <template
        v-for="(o, ok) in groups"
        >
        <div :class="'group' + (selected_group == ok ? ' selected ' : '')">
          <div class="nm">
            {{ ok }}
          </div>
          <div class="ls">
            <NuxtLink               
              v-for="(i,ik) in o"
              :key="ik" 
              :class="{item: true, selected: selected_group == ok && selected_position == i?.index}"
              :to="i?.link"               
              @click.native="hidePopup"
              >              
              <img loading="lazy" class="ava" v-if="i?.avatar" :src="i?.avatar"/>
              {{ i?.name }}              
            </NuxtLink>
          </div>
        </div>
      </template>
    </div>



  <NuxtLink class="create-diary" to="/diaries/edit/new">
    <i class="icon-plus"/>
    <span class="nm">{{ $t('main_menu_start_diary') }}</span>
  </NuxtLink>

  </div>
    



</template>


<script setup lang="ts">

import { watchArray } from '@vueuse/core'
import { onClickOutside } from '@vueuse/core'

interface PageProps {
  placeholder: string
}

const props = withDefaults(defineProps<PageProps>(), {
  placeholder: ''  
})


const router = useRouter()
const { t } = useI18n()
const { $api, $ga } = useNuxtApp()
const emits = defineEmits(['setActive'])
const elPopup = ref(null)

const name = ref('')
const results = ref([])
const show_popup = ref(false)
const show_clear = ref(false)
const show_loader = ref(false)
const show_focus = ref(false)
const selected = ref(null)
const selected_position = ref(null)
const selected_group = ref(null)
const timer = ref(null)
const placeholderInput = ref(props.placeholder)

const groups = computed(() => {
  var res = {};
  for(var r in results.value){
    
    if(typeof res[results.value[r].type] == 'undefined'){
      res[results.value[r].type] = [];
    }
    results.value[r].index = r;
    res[results.value[r].type].push(results.value[r]);
  }
  return res;
})

watch(name, (val) => {
  if(val.length > 0){
    toggleClear(true);
  }else{
    toggleClear(false);
  }
})


watchArray([props], ([new_props]) => {
  if(new_props.placeholder)
  placeholderInput.value = new_props.placeholder;
})

const clear = () => {
  name.value = '';
  toggleClear(false);
  toggleLoader(false);
  hidePopup();
  if(timer.value)
    clearTimeout(timer.value);
}

const startSearch = (e) => {

  if(e.keyCode == 13)
    return enterSearch();

  if(e.keyCode == 40)
    return listDown();
   
  if(e.keyCode == 38)
    return listUp();
    
  if(timer.value)
    clearTimeout(timer.value);

  timer.value = setTimeout(() => {
    load();
  }, 200);

  eventActiveSearch();

}

const setSelectedGroup = () => { 
  if(selected_position.value != null){
    selected_group.value = results.value[selected_position.value].type
    selected.value = results.value[selected_position.value]
  }
    
}

const listUp = () => {       
  if(results.value.length > 1){
    if(selected_position.value == null || selected_position.value == 0){
      selected_position.value = results.value.length - 1;
    }else{
      selected_position.value--;
    }
  }else{
    selected_position.value = null
  }
  setSelectedGroup();
}

const listDown = () => { 
  if(results.value.length > 1){
    if(selected_position.value == null){
      selected_position.value = 0;
    }else if(selected_position.value == results.value.length - 1){          
      selected_position.value = 0;
    }else{
      selected_position.value++;
    }
  }else{
    selected_position.value = null
  }
  setSelectedGroup();
}

const hidePopup = () => { 
  if(!show_popup.value)
    return;
  togglePopup(false);
}

const togglePopup = (state) => { 
  show_popup.value = state;
}

const toggleFocus = (state) => { 

  show_focus.value = state;


  // eventActiveSearch(state);
}
const eventActiveSearch = () => { 
  let state = false;
  if(name.value){
    state = true;
  }
  emits('setActive', state);
}
const toggleLoader = (state) => { 
  show_loader.value = state;
}

const toggleClear = (state) => { 
  show_clear.value = state;
}

const load = () => {  
  toggleLoader(true);
  $api.getGlobalSearch(name.value.trim())
  .then((res) => { 
    toggleLoader(false);
    togglePopup(res.length > 0);
    console.log('res');
    console.log(res.length);
    results.value = res;                 
    selected.value = res[0];
    selected_position.value = 0;
    setSelectedGroup();
    $ga.eventGA('fast_search', 'search', 'result');
  })
  .catch((error) => { 
    togglePopup(false);
    toggleLoader(false);
    console.log(error);
    console.log('error');
  })      
}

const enterSearch = () => {    
  if(selected_position.value !== null){
    var el = results.value[selected_position.value];
    
    router.push({
      path: decodeURIComponent(el.link)
    });

    hidePopup()
  }
    
}

 

onClickOutside(elPopup, (event) => {
  hidePopup()
  // event.stopPropagation()
})


</script>
 


<style>  

.ts{
  margin: 8px;
  position: relative;
  max-width: 380px;
  width: 100%;
  
  margin-right: auto;
}
.ts input{
  margin: 0em;
  max-width: 100%;
  flex: 1 0 auto;
  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  text-align: left;
  line-height: 1.2142em;
  font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  padding: 0.77861429em 1em;
  background: var(--gd-topbar-search-back-color);
  border: var(--gd-topbar-search-border);
  color: var(--gd-topbar-search-color);
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  box-shadow: none;
  border-radius: 30px;
  width: 100%;
}
.ts .popup{
  display: none;
  left: 2px;
  top: 42px;
  background-color: var(--gd-popup-back-color);
  z-index: 10;
  padding: 0;
  border-radius: 3px;
  box-shadow: 0px 2px 3px 0px rgb(34 36 38 / 15%);
  border: 1px solid rgba(34, 36, 38, 0.15);
  min-width: 390px;  
  max-height: 415px;
  overflow-y: auto;
}
.ts.active .popup{
  display: block;
  position: absolute;
}
.ts .clear{
position: absolute;
    right: 7px;
    top: 4px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    display: flex;
}
.ts .clear i{
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
} 
.ts.searching .clear .isr{

}
.ts .clear .isr{
  display: flex;
}
.ts.searching .clear .icl{
  display: flex;
}
.ts .clear .icl{
  display: none;
  color: #adadad;
  right: 30px;
  left: auto;
}
.ts .clear .gloading{
  display: none;
}
.ts.loading .clear .isr{
  display: none;
}
.ts.loading .clear .gloading{
  display: flex;
}

@media (max-width: 768px) {
  
  .ts{
    display: none;
  }

}




/* popup */

.ts .popup .group{
  display: flex;
  width: 100%;
  border-bottom: 1px var(--gd-popup-border-color) solid;
}
.ts .popup .group .nm{
  background: var(--gd-popup-back-categories-color);
  padding: 10px;
  flex-shrink: 0;
  width: 30%;
  font-weight: bold;

  text-align: left;
  text-transform: capitalize;
}
.ts .popup .group .ls{
  width: 100%;
}
.ts .popup .group .item{
    padding: 10px;
    border-bottom: 1px var(--gd-popup-border-color) solid;
    display: flex;
    align-items: center;
    text-align: left;
    font-weight: bold;
}
.ts .popup .group .item:last-child{
  border-bottom: none;
}
.ts .popup .group .item.selected{
  /* background-color: whitesmoke; */
}
.ts .popup .group .item .ava{
    width: 25px;
    height: 25px;
    border-radius: 30px;
    margin-right: 10px;
    flex-shrink: 0;
}

/*  */



/* for main page */


@media (max-width: 768px) {


  .srch .ts{
    display: block;
    margin: 0;
    margin-top: 8px;
  }

  .srch .ts input {
    border-radius:5px!important; 
    padding: 20px 20px;
    padding-right: 20px!important;
    font-size: 1rem;
  }
  .srch .ts .clear .isr{
    font-size: 1.5rem;
    color: gray;
  }
  .srch .ts .clear{

    width: 42px;
    height: 54px;
  }
  .srch .ts .clear .icl{
    font-size: 1.2rem;
    right: 40px;
  }
  .srch .ts .popup{
    left: 0px;
    top: 69px;
    min-width: auto;
    width: 100%;
  }
  
}



/*  */

.create-diary{
  color: white;
    padding: 0.4rem;
    display: none;
    justify-content: center;
    gap: 0.4rem;
    font-weight: 600;
    text-shadow: 0px 0px 10px black, 0px 0px 10px black;
    margin-top: 2.6rem;
    border: 1px white solid;
    max-width: 190px;
    border-radius: 30px;
    margin: 0 auto;
    margin-top: 1.8rem;
}


@media (max-width: 768px) {
  .create-diary{
    display: flex;
  }
}

</style>